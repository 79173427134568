<template>
    <div class="columns is-multiline">
        <div class="column is-two-thirds-desktop">
            <div class="field">
                <label class="label">{{ $t('Office delivery') }}</label>
                <v-select
                    id="pickup-point-select"
                    v-model="pickupPointObject"
                    :options="currentPickupPoints"
                    label="search_string"
                    @search="searchPickupPoints($event)"
                />
                <p class="help">{{ $t('Start typing to search locations') }}</p>
                <input
                    name="magyar_pickup_point_parcel_shop_id"
                    :value="pickupPointObject ? pickupPointObject.id : null"
                    type="text"
                    hidden
                >
                <input
                    name="magyar_city_pickup_point"
                    :value="pickupPointObject ? pickupPointObject.city : null"
                    type="text"
                    hidden
                >
                <input
                    name="magyar_post_number_pickup_point"
                    :value="pickupPointObject ? pickupPointObject.post_code : null"
                    type="text"
                    hidden
                >
                <input
                    name="magyar_street_pickup_point"
                    :value="pickupPointObject ? pickupPointObject.street : null"
                    type="text"
                    hidden
                >
                <input
                    name="magyar_house_number_pickup_point"
                    :value="houseNumberFormatted"
                    type="text"
                    hidden
                >
                <input
                    name="magyar_pickup_point_type"
                    :value="pickupPointObject ? pickupPointObject.service_point_type : null"
                    type="text"
                    hidden
                >
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios';
import _ from 'lodash';

export default {
    name: "MagyarPickupPointSelect",

    props: {
        selectedPickupPointId: {
            required: true,
            default: null,
            type: String
        },
        initialPickupPoints: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            pickupPointObject: {
                id: null,
                name: null,
                search_string: null,
                city: null,
                post_code: null,
                street: null,
                house_number: null,
                service_point_type: null,
            },
            currentPickupPoints: [],
        };
    },
    computed: {
        houseNumberFormatted() {
            return this.pickupPointObject?.house_number.trim() === '' ? '/' : this.pickupPointObject.house_number;
        }
    },
    watch: {
        pickupPointObject(newValue) {
            if (newValue === null) {
                this.searchPickupPoints('');
            }
        }
    },
    created() {
        this.currentPickupPoints = this.initialPickupPoints;
        if (this.selectedPickupPointId !== '') {
            this.findPickupPoint();
        }
    },
    methods: {
        findPickupPoint: function () {
            this.pickupPointObject =
                this.currentPickupPoints.find(element => element.id === parseInt(this.selectedPickupPointId));
        },
        searchPickupPoints: _.debounce(
            function (event) {
                axios.get('/magyar/search-pickup-points', {
                    params: {
                        search_word: event
                    }
                }).then(response => {
                    this.currentPickupPoints = response.data;
                });
            }, 500)

    },
};
</script>
<style>
.vs__search {
    height: 0 !important;
}
.vs__dropdown-toggle {
    height: auto !important;
    min-height: 45px;
}
.vs__search:focus {
    height: auto !important;
}
</style>
